import React from "react";
import Layout from "../layouts/home";

class Notfound extends React.Component {
  render() {
    return (
      <Layout
        location="/404"
        cssClass="notfound"
        title="Page not found"
        description="The page you are looking for cannot be found."
      >
        <section id="content">
          <div className="row">
            <div className="col-md-12 col-lg-10 col-xl-8">
              <p className="lead">Completely lost</p>
              <h1>The page you are looking for cannot be found.</h1>
            </div>
            <div className="col-md-6" id="visual">
              <div className="img-overview"></div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Notfound;
